



































import Vue from "vue";

export default Vue.extend({
  name: "App",
  computed: {},
  data: () => ({
    links: ["Breweries", "Devices", "Batches", "KPI"],

    drawer: false,
  }),
});
