import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import jwt_decode from "jwt-decode";
import router from "@/router";
import * as Sentry from "@sentry/vue";


const AUTH_TOKEN = "apollo-token";
const TB_TOKEN = "tb-token";
Vue.use(Vuex);
Vue.config.devtools = true;
const load = (key: string, value: any) =>
  JSON.parse(window.localStorage.getItem(key) ?? JSON.stringify(value));
const save = (key: string, value: any) =>
  window.localStorage.setItem(key, JSON.stringify(value));

const loadOktaToken = () => {
  const x = load(AUTH_TOKEN, {});
  if (x.expires_in > +new Date()) {
    return x;
  }
  return null;
};

const loadTBToken = (state: any) => {
  const x = load(TB_TOKEN, { expires_in: 0 });
  if (x.expires_in > +new Date()) {
    return x.token;
  } else {
    alert('your session ended, please login again.')
  }
  return null;
};

const tbLogin = async (credentials: any) =>
  await axios
    .post("https://console.zymoscope.com/api/login", credentials)


const default_user = {
  id: {
    entityType: null,
    id: null,
  },
  createdTime: null,
  additionalInfo: {},
  email: null,
  authority: null,
  firstName: null,
  lastName: null,
  name: null,
  ownerId: {},
};

export default new Vuex.Store({
  state: {
    api_token: null,
    okta_token: loadOktaToken(),
    user: load("user", default_user),
    loading: false,
    production: true,
    error: null as any,
    done: false,
    tb_auth: { username: null, password: null },
  },
  getters: {
    tb_api_token(state) {
      state.api_token = loadTBToken(state)
      return state.api_token
    },
    tb_api_headers(state) {
      return {
        headers: {
          "X-Authorization": loadTBToken(state),
        },
      }
    }
  },
  mutations: {
    update_tb_auth(state, { username, password }) {
      state.tb_auth.password = password
      state.tb_auth.username = username
    },
    update_api_token(state, { token, expires_in }) {
      save("api_token", token)
      save(TB_TOKEN, { token, expires_in })
      state.api_token = token;
    },
    update_user(state, payload) {
      window.localStorage.setItem("user", JSON.stringify(payload));
      state.user = payload;
    },
  },
  actions: {
    async login(context, { username, password }) {
      context.commit('update_tb_auth', { username, password })
      try {
        const result = await tbLogin({ username, password })
        context.state.error = null;
        const token = "Bearer " + result?.data?.token.token;
        const decoded: any = jwt_decode(result?.data?.token.token);
        window.console.log(decoded);
        const refresh = result?.data?.token.refreshToken;
        window.console.log(refresh);
        Sentry.setUser({ email: username });
        context.commit("update_user", result?.data.user);
        context.commit("update_api_token", {
          token: token,
          expires_in: decoded.exp * 1000
        });
        context.dispatch("load_assets");
      } catch (error) {
        context.state.error = error
      }
    },
    oktaCallback(context, { jwt }) {
      context.state.okta_token = jwt || {
        access_token: null,
        token_type: null,
        expires_in: null,
        scope: null,
        state: null,
      };

      context.state.okta_token = {
        ...context.state.okta_token,
        expires_in: context.state.okta_token.expires_in * 1000 + +new Date(),
      };

      window.console.info("token", context.state.okta_token);
      window.console.info("k", JSON.stringify(context.state.okta_token));

      localStorage.setItem(
        AUTH_TOKEN,
        JSON.stringify(context.state.okta_token)
      );
      // Sentry.setUser({ email: username });

      if (!context.state.done) {
        context.state.done = true;
        router.push("/breweries");
      }
    },
  },
  modules: {},
});
