import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    // component: Home,
    component: () => import("../views/Breweries.vue"),
  },
  {
    path: "/breweries",
    name: "Breweries",
    component: () => import("../views/Breweries.vue"),
    children: [],
  },

  {
    path: "/brewery/:id",
    name: "Brewery",
    component: () => import("../views/Brewery.vue"),
    children: [],
  },
  {
    path: "/devices",
    name: "Devices",
    component: () => import("../views/Devices.vue"),
  },
  {
    path: "/device/:id",
    name: "Device",
    component: () => import("../views/Device.vue"),
    children: [],
  },
  {
    path: "/batches",
    name: "Batches",
    component: () => import("../views/Batches.vue"),
  },
  {
    path: "/batch/:id",
    name: "Batch",
    component: () => import("../views/Batch.vue"),
  },
  {
    path: "/batch/compare/:id",
    name: "Batch",
    component: () => import("../views/Batch-Compare.vue"),
  },
  {
    path: "/login/callback",
    name: "callback",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Callback.vue"),
  },
  {
    path: "/token",
    name: "token",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Token.vue"),
  },
  {
    path: "/kpi",
    name: "kpi",
    component: () => import("../views/Kpi.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
